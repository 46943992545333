.inputmoney_topic {
  text-align: center;
}
.inputmoney_container {
  display: flex;

  background: var(--color-white);
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  min-width: 380px;
  min-height: 450px;
  padding: 1.7rem;
}
.inputmoney_balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 10px;
}
.inputmoney_balance_div_one {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  gap: 0.7rem;
}
.inputmoney_balance_div_one span {
  font-size: 15px;
  font-weight: 300;
}

.label_and_input {
  display: flex;
  width: 100%;
  /* padding-bottom: 1rem; */
}

.label_currency {
  width: 50%;
}
.input_currency {
  width: 100%;
  box-sizing: border-box;
  margin: 1rem 0rem;
}
.select_currency {
  width: 100%;
  border: 1px solid rgb(173, 173, 173);
  border-right: none;
  border-radius: 10px 0px 0px 10px;
  padding: 1rem 0;
  outline: none;
}
.input_currency_amount {
  width: 100%;
  padding-right: 20px;
  text-align: right;
  direction: ltr;
  padding: 1rem 0.5rem;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 0px 10px 10px 0px;
  border-left: none;
  outline: none;
}
.inputmoney_fee {
  background: rgb(233, 232, 232);
  border-radius: 2rem;
  margin: 1rem 0;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
}
.inputmoney_fee div {
  display: flex;
  justify-content: space-between;
}
.inputmoney_fee div span {
  color: rgb(136, 132, 132);
}
.inputmoney_btn_continue{
  padding: 0.8rem 0rem;
  border-radius: 1rem;
  border: none;
  color: #fff;
  background: #000;
}
