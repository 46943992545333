.PaymentByBtc_section{
    min-height: 100vh;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.PaymentByBtc_container{
    
}