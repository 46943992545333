.appbar_conatiner a{
    color: var(--color-white);
    text-decoration: none;
    
}
.appbar_conatiner{  
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.239);
}
.appbar_menuitem a{
    text-decoration: none;
    color: var(--color-dark);
    display: flex;
}
.appbar_menuitem a:hover{
    color: var(--color-primary);
}
.toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;
}
