body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-greytext);
}
html {
  scroll-behavior: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-bg: #fbfbfc !important;
  --color-bg-variant: #2c2c6c;
  --color-primary: #68c8f4;
  --color-secondary: #54595f;
  --color-gold:#cca354 !important;
  --color-accent: #29211b;
  --color-darker: #161616;
  --color-white: #ffffff;
  --color-dark: #000000;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-text: #3f475a;
  --color-greytext: #d9d9d9;
  --color-grey-old: #383243;
  --color-grey-new: #1a1722;
  --color-background: #0f0f0f;
  --transition: all 400ms ease;
  --container-width-lg: 87%;
  --container-width-md: 90%;
  --container-width-sm: 95%;
}

.container{
  width: var(--container-width-lg);
  margin: 0 auto;
}
.btn_custom {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}
.btn_custom:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}
.btn_cutom-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

/* MEDIA QUERY FOR SMALL DEVICES */

@media (max-width: 500px) {
  /* section {
    height: 75vh;
    margin-bottom: 2rem;
  } */
  .container {
    width: var(--container-width-sm);
  }
}
