.fund_container{
    background: #fff;
    min-height: 50vh;
}
.funds_balance{
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
}
.funds_transfer_section{
    margin: 2rem 0;
    border-radius: 10px;
    border: 0.1px solid rgb(173, 173, 173);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}