.mybtn_styles{
    color: #fff;
        background: #000;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border:0.2px solid #fff;
        padding: 0.5em 2rem;
        cursor: pointer;
        border-radius: 1.3rem;
}
.link_btn{
    text-decoration: none;
}