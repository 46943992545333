.currency_label{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0.5rem;
}
.available_balance{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 2rem;
}
.currency_naira{
    display: flex;
    align-items: center;
}
.naira_available_table{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* .pending_balance_naira{
    border-left: 0.1px solid rgb(173, 173, 173);
} */

.naira_pending_table{
    border-left: 0.1px solid rgb(173, 173, 173);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.convert_withdraw{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    gap: 3rem;
}
.btn_convert{
    color: #000;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border:0.2px solid #000;
    padding: 0.5em 2rem;
    cursor: pointer;
    border-radius: 0.4rem;
    gap: 0.5rem;
}