.ordersection_container{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    background: var(--color-white);
    border-radius: 4px;
    height: 40vh;
    padding: 1rem 0;
}
.ordersection_col{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.8rem 0;
}
.ordersection_coltwo{
    display: flex;
    justify-content: flex-end;
}
