.buysection_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-white);
    border-radius: 4px;
}
.buysection_colone{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.buysection_coltwo{
    display: flex;
    justify-content: flex-end;
}
.invest_btn{
    text-decoration: none;
    color: var(--color-white);
    background: var(--color-dark);
    padding: 0rem 1rem ;
    margin: 5px;
    border-radius: 10px;
    transition: 0.3s all;
    display: flex;
    align-items: center;
}
.invest_btn:hover{
    color: var(--color-dark);
    background: var(--color-white);
    border: 0.5px solid var(--color-dark);
}

@media (max-width: 768px) {
    .invest_btn{
        padding: 0rem 2rem;
    margin: 0.8rem;
    text-align: center;
    }
}
