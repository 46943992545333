.help_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    justify-content: center;
    height: 50vh;
}
.btn_contact_us{
    margin: 1rem 2rem;
    border-radius: 5px;
    padding: 1rem;
    font-size: medium;
    color: var(--color-bg);
    background-color: var(--color-primary);
}