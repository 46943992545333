.ammt_container {
    border: 0.5px solid var(--color-accent);
    border-radius: 10px;
    min-height: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .ammt_form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
  }
  .sendbtc_address{
    color: red;
    cursor: pointer;
  }

  .ammt_btn {
    color: #fff;
    background: #000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0.2px solid #fff;
    padding: 0.5em 2rem;
    margin: 1rem 0;
    cursor: pointer;
    border-radius: 1.3rem;
  }
  .ammt_btns{
      display: flex;
  
  }
  .ammt_form_div h6{
    border-top: 0.5px dotted var(--color-accent);
    margin: 0.8rem 0rem;
  }
  .upload_btn{
    margin: 1rem 0.5rem;
    border: none;
    border: 0.2px solid var(--color-accent);
    padding: 0.8rem 0.5rem ;
    border-radius: 8px;
    background: var(--color-gold);
  }
