.portfolio_container{
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white);
}

.activity{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.activity h5{
    margin-bottom: 2rem;
    font-weight: 400;
}
.activity div{
    font-weight: 200;
}
.sectionthree_container{
    display: none;
    }
@media (max-width: 768px) {
    .sectionthree_container{
    display: flex;
    }
}