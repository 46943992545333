.sectionfour_container{
    background-color: #080013;
    border-top: 1px solid white;
}
.sectionfour_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}
.col_img{
    width: 100px; /* Adjust the width as needed */
      height: 100px; /* Adjust the height as needed */
      border-radius: 50%;
}
.sectionfour_col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}