
.invest_btn1{
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 0.5rem 0.5rem;
    margin: 1rem 4rem;
    background-color: #cca354;
    
}
.invest_btn1:hover{
    background-color: #fff; 
    color: #cca354;
}
.amateur_heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modal299max_container{
    background:#343a40 ;
}

