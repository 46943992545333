.forgot_password_container{
    height: 100vh;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-bg);
  }
  .forgot_password_section {
    display: flex;
    background: var(--color-white);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    min-height: 450px;
  }
  .forgot_password_section h1 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
  .forgot_password_email {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }
  .email_input {
    width: 320px;
    padding: 14px;
    margin-top: 0.7rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
  }
  .forgot_password_password div{
      position: relative;
  }
  .forgot_password_password_label{
      display: flex;
      justify-content: space-between;
  }
  .forgot_password_password {
    display: flex;
    flex-direction: column;
  }
  .password_input {
    margin-top: 0.7rem;
    width: 320px;
    padding: 14px;
    border-radius: 10px;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
  }
  .forgot_password_eye {
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .create_proceed_btn {
    color: #fff;
    background: #000;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0.2px solid #fff;
    padding: 0.5em 2rem;
    cursor: pointer;
    border-radius: 1.3rem;
  }
  @media (max-width: 768px) {
    .forgot_password_container {
      height: 70vh;
    }
  }
  