.sectiontwo_container{
    height: fit-content;
}
.SectionTwo_colone{
    background-color: #080013;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    text-align: left;
    padding-bottom: 1rem;
}
.SectionTwo_colone p{
    margin-top: 1rem;
}
.SectionTwo_colone h5{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
}

.SectionTwo_coltwo{
    background-image: url('./../../../assets/cryptoportfolio.jpg'); /* Replace with the actual path to your image */
  
  /* Set the background size and other properties */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  /* Set the size of the section (optional) */
  min-height: 570px;
}

/* SMALLER SCREENS */

@media (max-width: 768px){
    .SectionTwo_coltwo{
        display: none;
    }
    .SectionTwo_colone{
        min-height: 350px;
    }
    .SectionTwo_colone{
        gap: 1rem;
    }
    .SectionTwo_colone h5{
        font-size: 12px;
    }
}