.create_container {
  display: flex;
  background: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 450px;
  padding: 1.7rem;
}
.create_top{
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
}
.create_back{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20rem;
}
.create_container h1 {
  font-size: 1.6rem;
}
.create_container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
.create_container input[type="radio"]:checked {
  background-color: var(--color-primary); /* Change this color to your preference */
  border-color: var(--color-primary);
}
.create_label{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 8px 0;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 15px;
    padding: 0.8rem 1.2rem;
    cursor: pointer;
}
.create_label h5{
    font-size: 1rem;
}
/* .create_label input{
    
} */
.create_proceed_btn {
  color: #fff;
  background: #000;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0.2px solid #fff;
  padding: 0.5em 2rem;
  cursor: pointer;
  border-radius: 1.3rem;
}

@media (max-width: 768px) {
  .create_container {
    height: 70vh;
  }
}
