.sectione_container{
    height: 100vh;
     /* Set the background image */
  background-image: url('./../../../assets/bgg.png'); /* Replace with the actual path to your image */
  background-repeat: no-repeat;
  background-size: cover;
  
  /* Set the background size and other properties */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  /* Set the size of the section (optional) */
  min-height: 400px;
  /* position: absolute; */
}

.sectione_section{
color: var(--color-white);
}
.sectionone_containerrow{
    height: 80vh;
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.sectione_container .sectionone_h1{   
    font-size: 4.5rem;
    font-weight: bolder;
}
.sectionone_containerrow p{
      font-size: 1.5rem; 
      /* max-width: 500px; */
}

.btc_div{
    min-width: 450px;
    border-radius: 20px;
    color: var(--color-dark);
    background: var(--color-white);
    padding: 1rem 1.1rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btc_symbol{
    color: rgb(251, 165, 165);
    font-size: 1.3rem;
}
.btc_title{
    font-size: 1.3rem;
}
.btc_pnl{ color: red;}
.btc_value{
    color: green;
}
.btn_two{
    display: none;
}

/* SMALLER SCREENS */

@media (max-width: 768px){
    .sectione_container{
        height: 60vh;
    }
    .sectionone_containerrow{
        height: 60vh;
        padding-top: 2rem;
        width: 100%;
    }
    .sectione_container .sectionone_h1{   
        font-size: 2.3rem;
        padding-bottom: 1.1rem;
        font-weight: bolder;
    }
    .sectionone_containerrow p{
       font-size: 1rem;
       padding-bottom: 1rem;
    }
    .btc_div{
        display: none;
    }
    .btc_symbol{
        color: rgb(251, 165, 165);
        font-size: 1rem;
    }
    .btc_title{
        font-size: 1rem;
    }
    .btc_pnl{ color: red;}
    .btc_value{
        color: green;
    }
    .btn_two{
        display: flex;
    }
}