
.dashboardnav_container a {
  /* color: var(--color-dark); */
  color: #fff !important;
  font-size: 0.9rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  background: var(--color-dark);
  width: 100%;
  border: 0.5px solid #cca354;
  border-radius: 4px;

}
.dashboardnav_container a:hover {
  color: var(--color-dark);
  background: var(--color-white);
  border-radius: 4px;
  width: 100%;
}
.main_dashboard_nav {
  background: #000;
  padding: 2rem 0.5rem;
  margin-top: 2rem;
  border-radius: 2rem;
  align-items: center;
}
.main_dashboard_nav h4 {
  color: #cca354 !important;
  margin: 3rem 2rem;
}
.main_dashboard_nav ul {
  list-style: none;
}
.main_dashboard_nav a {
  text-decoration: none;
}
.main_dashboard_nav li {
  padding: 0.7rem;
}

.menuToggle {
  cursor: pointer;
  display: none; /* Hide the hamburger menu on larger screens */
  
}


.hamburgerIcon {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: transform 0.3s ease;
}

/* Media query for screens with a maximum width of 768px */


@media (max-width: 768px) {
  .main_dashboard_nav {
    background: #000;
    margin-top: 0.5rem;
  }
  .dashboardnav_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main_dashboard_nav {
    background: #000;
    padding: 2px ;
    border-radius: 2rem;
  }
  .navbar_menu{
    color: #cca354;
  }
  .navbar_hamburger{
    color: #cca354;
  }
  .dashboard_navv{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: center;
  }
  .main_dashboard_nav h4 {
    margin: 1rem 0.6rem;
  }
  h4 {
    margin-bottom: 20px; /* Adjust spacing for smaller screens */
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
  }

  /* Optional: Add additional styles for smaller screens as needed */

  .menuToggle {
    display: block; /* Show the hamburger menu on smaller screens */
    transition: transform 0.3s ease;
  }
 

  .dashboard_nav ul {
    list-style-type: none;
    padding: 0;
    display: none; /* Hide the menu by default on smaller screens */
  }

 
  ul.showMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}
